import { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
import { useNavigate } from "react-router-dom";

import ReactPixel from "../../utils/facebookPixel";
import { Input, BtnPrimary, BtnSelect } from "../../components/commonComponents";
import estimatesJson from "../../resources/estimates.json";

const Landing = () => {
    const inputRef = useRef();
    const [estimatesList, setEstimatesList] = useState(estimatesJson);
    const [step, setStep] = useState(1);
    const [address, setAddress] = useState("");
    const [latLng, setLatLng] = useState();
    const [propertyType, setPropertyType] = useState("");
    const [possibleBedrooms, setPossibleBedrooms] = useState([]);
    const [bedrooms, setBedrooms] = useState();
    const [possibleBathrooms, setPossibleBathrooms] = useState([]);
    const [bathrooms, setBathrooms] = useState();
    let navigate = useNavigate();

    // const [cities, setCities] = useState([]);

    const handlePlaceChanged = () => {
        const [place] = inputRef.current.getPlaces();
        if (place) {
            console.log(place);
            console.log(place.formatted_address);
            console.log(place.geometry.location.lat());
            console.log(place.geometry.location.lng());
            setAddress(place.formatted_address);
            setLatLng({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() });
            setStep(2);
        } else {
            alert("Not a valid address.");
        }
    };

    const setPropertyTypeHelper = (type) => {
        setPropertyType(type);
        let filterdByPropertyType = estimatesList.filter((estimate) => {
            return estimate["Property Type"] === type;
        });
        setEstimatesList(filterdByPropertyType);
        console.log(filterdByPropertyType);
        let uniqueBedrooms = [...new Set(filterdByPropertyType.map((item) => item.Bedrooms))];
        for (let i = 0; i < uniqueBedrooms.length; i++) {
            uniqueBedrooms[i] = parseInt(uniqueBedrooms[i]);
        }
        console.log(uniqueBedrooms);
        setPossibleBedrooms(uniqueBedrooms);
        setStep(3);
    };

    const setBedroomsHelper = (num) => {
        setBedrooms(num);
        let filteredByBedrooms = estimatesList.filter((estimate) => {
            return estimate["Bedrooms"] === num.toString();
        });
        console.log(filteredByBedrooms);
        let uniqueBathrooms = [...new Set(filteredByBedrooms.map((item) => item.Bathrooms))];

        for (let i = 0; i < uniqueBathrooms.length; i++) {
            uniqueBathrooms[i] = parseInt(uniqueBathrooms[i]);
        }
        console.log(uniqueBathrooms);
        setPossibleBathrooms(uniqueBathrooms);
        setStep(4);
    };

    const goToEstimate = () => {
        const estimateObj = {
            address,
            propertyType,
            bedrooms: bedrooms.toString(),
            bathrooms: bathrooms.toString(),
            lat: latLng.lat,
            lng: latLng.lng,
        };

        console.log(estimateObj);
        localStorage.setItem("estimateObj", JSON.stringify(estimateObj));
        navigate("/your-home-estimate");
    };

    const renderStep = () => {
        switch (step) {
            case 1:
                return (
                    <>
                        <H3>Enter your property address</H3>
                        <LoadScript
                            googleMapsApiKey={process.env.REACT_APP_GMAPS_API}
                            libraries={["places"]}
                        >
                            <StandaloneSearchBox
                                onLoad={(ref) => (inputRef.current = ref)}
                                onPlacesChanged={handlePlaceChanged}
                            >
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Location"
                                />
                            </StandaloneSearchBox>
                        </LoadScript>
                    </>
                );

            case 2:
                return (
                    <>
                        <H3>What is your property type?</H3>
                        <Row>
                            <BtnSelect onClick={() => setPropertyTypeHelper("Detached House")}>
                                Detached House
                            </BtnSelect>
                            <BtnSelect onClick={() => setPropertyTypeHelper("Semi-Detached House")}>
                                Semi-detached House
                            </BtnSelect>
                            <BtnSelect onClick={() => setPropertyTypeHelper("Townhouse")}>
                                Townhouse
                            </BtnSelect>
                            <BtnSelect onClick={() => setPropertyTypeHelper("Condo")}>
                                Condo
                            </BtnSelect>
                        </Row>
                    </>
                );

            case 3:
                return (
                    <>
                        <H3>How many bedrooms does the property have?</H3>
                        <Row>
                            {possibleBedrooms.map((bedroomNum, id) => {
                                return (
                                    <BtnSelect
                                        key={id}
                                        onClick={() => setBedroomsHelper(bedroomNum)}
                                    >
                                        {bedroomNum}
                                    </BtnSelect>
                                );
                            })}
                            {/* <BtnSelect onClick={() => setBedroomsHelper(0)}>0</BtnSelect>
                            <BtnSelect onClick={() => setBedroomsHelper(1)}>1</BtnSelect>
                            <BtnSelect onClick={() => setBedroomsHelper(2)}>2</BtnSelect>
                            <BtnSelect onClick={() => setBedroomsHelper(3)}>3</BtnSelect>
                            <BtnSelect onClick={() => setBedroomsHelper(4)}>4</BtnSelect>
                            <BtnSelect onClick={() => setBedroomsHelper(5)}>5</BtnSelect> */}
                        </Row>
                    </>
                );

            case 4:
                return (
                    <>
                        <H3>How many bathrooms does the property have?</H3>
                        <Row>
                            {possibleBathrooms.map((bathroomNum, id) => {
                                return (
                                    <BtnSelect
                                        selected={bathrooms === bathroomNum}
                                        key={id}
                                        onClick={() => setBathrooms(bathroomNum)}
                                    >
                                        {bathroomNum}
                                    </BtnSelect>
                                );
                            })}
                            {/* <BtnSelect selected={bathrooms === 1} onClick={() => setBathrooms(1)}>
                                1
                            </BtnSelect>
                            <BtnSelect selected={bathrooms === 2} onClick={() => setBathrooms(2)}>
                                2
                            </BtnSelect>
                            <BtnSelect selected={bathrooms === 3} onClick={() => setBathrooms(3)}>
                                3
                            </BtnSelect>
                            <BtnSelect selected={bathrooms === 4} onClick={() => setBathrooms(4)}>
                                4
                            </BtnSelect>
                            <BtnSelect selected={bathrooms === 5} onClick={() => setBathrooms(5)}>
                                5
                            </BtnSelect> */}
                        </Row>

                        {bathrooms > 0 && (
                            <BtnPrimary onClick={() => goToEstimate()}>Estimate</BtnPrimary>
                        )}
                    </>
                );

            default:
                return null;
        }
    };

    useEffect(() => {
        ReactPixel.track("Estimator-Landing");
    }, []);

    return (
        <Main>
            <Label>Step {step} of 4:</Label>
            {renderStep()}
        </Main>
    );
};
export default Landing;

const Main = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #e2b15d;
    border-radius: 30px;
    margin: 30px;
    width: calc(100% - 60px);

    @media (max-width: 560px) {
        border-radius: 20px;
        margin: 20px;
        width: calc(100% - 40px);
    }
`;

const H3 = styled.h3`
    text-align: center;
    max-width: 90%;
    margin-top: 0;
`;

const Label = styled.label`
    text-align: center;
    max-width: 90%;
`;

const Row = styled.div`
    display: flex;

    @media (max-width: 560px) {
        flex-direction: column;
        align-items: center;
    }
`;

// const uniqueItems = (arr, key) => {
//     return [...new Set(arr.map((item) => item[key]))];
// };
