import styled from "styled-components";

export const Input = styled.input`
    background-color: transparent;
    border: 2px solid white;
    border-radius: 30px;
    padding: 8px 15px;
    color: white;
    width: 380px;
    text-align: center;

    ::placeholder {
        color: white;
    }

    @media (max-width: 560px) {
        width: 280px;
    }
`;

export const BtnPrimary = styled.button`
    background-color: #5b331e;
    padding: 8px 30px;
    color: white;
    border-radius: 30px;
    margin-left: 15px;
    margin-top: 15px;
    cursor: pointer;
    transition: background-color 0.1s ease-in-out;

    @media (max-width: 560px) {
        margin-left: 0;
        margin-top: 15px;
    }

    :hover {
        background-color: #000000;
    }
`;

export const BtnSecondary = styled.button`
    background-color: #e2b15d;
    padding: 8px 30px;
    color: #1c212b;
    border-radius: 30px;
    margin-top: 25px;
    cursor: pointer;
    transition: background-color 0.1s ease-in-out;

    @media (max-width: 560px) {
        margin-left: 0;
        margin-top: 15px;
    }

    :hover {
        background-color: #e2b15d;
    }
`;

export const BtnSelect = styled.button`
    background-color: ${({ selected }) => (selected ? "#5b331e" : "transparent")};
    border: 2px solid ${({ selected }) => (selected ? "#5b331e" : "white")};
    border-radius: 10px;
    padding: 8px 15px;
    color: white;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.1s ease-in-out;

    @media (max-width: 560px) {
        margin: 5px 0;
    }

    :hover {
        background-color: #5b331e;
    }
`;
