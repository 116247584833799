import ReactPixel from "react-facebook-pixel";

const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
};

console.log(typeof process.env.REACT_APP_PIXEL_ID);
console.log(parseInt(process.env.REACT_APP_PIXEL_ID));

ReactPixel.init(parseInt(process.env.REACT_APP_PIXEL_ID), advancedMatching, options);

export default ReactPixel;
