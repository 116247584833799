import React from "react";
import styled from "styled-components";

const Spinner = () => {
    return (
        <Main>
            <div className="spinner">
                <div className="spinner-inner"></div>
            </div>
        </Main>
    );
};

export default Spinner;

const Main = styled.div`
    .spinner {
        position: relative;
        width: 40px;
        height: 40px;
        margin: 20px auto;
    }

    .spinner-inner {
        position: absolute;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 4px solid #f3f3f3;
        border-top-color: black;
        animation: spin 1s ease-in-out infinite;
    }

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
`;
