import { createBrowserRouter, Navigate } from "react-router-dom";

import MainWrapper from "./MainWrapper";
import Landing from "./Landing";
import Estimate from "./Estimate";

const router = createBrowserRouter([
    {
        path: "/",
        element: <MainWrapper />,
        children: [
            {
                path: "/",
                element: <Navigate to="/about-your-home" replace />,
            },
            {
                path: "/about-your-home",
                element: <Landing />,
            },
            {
                path: "/your-home-estimate",
                element: <Estimate />,
            },
        ],
    },
]);
export default router;
