import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import ReactPixel from "../../utils/facebookPixel";
import { fetchPOST } from "../../utils/api";
import { Input as InputRaw, BtnSecondary } from "../../components/commonComponents";
import estimatesJson from "../../resources/estimates.json";
import Spinner from "../../components/Spinner";

import MapWithMarker from "../../components/MapWithMarker";

const Estimate = () => {
    const [loading, setLoading] = useState(false);
    const [showMap, setShowMap] = useState(false);
    const [success, setSuccess] = useState(false);
    const [userDetails, setUserDetails] = useState();
    const [propertyEstimate, setPropertyEstimate] = useState();
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    let navigate = useNavigate();

    const submit = () => {
        if (name.length === 0 || phone.length < 10) {
            alert("Please enter a valid name and 10 digit phone number.");
            return;
        }

        setLoading(true);

        ReactPixel.track("Estimator-User Submit Details");

        let callBody = JSON.parse(JSON.stringify(userDetails));

        callBody.name = name;
        callBody.phone = phone;

        fetchPOST(`/api/messages`, callBody)
            .then((res) => {
                console.log(res);
                setSuccess(true);
            })
            .catch((e) => {
                console.log(e.message);
            });
    };

    useEffect(() => {
        ReactPixel.track("Estimator-Reviewing Estimate");

        let userPropertyDetails = localStorage.getItem("estimateObj");
        if (userPropertyDetails) {
            userPropertyDetails = JSON.parse(userPropertyDetails);
            setUserDetails(userPropertyDetails);
            let propertyEstimateObj = estimatesJson.find((estimate) => {
                return (
                    estimate["Property Type"] === userPropertyDetails.propertyType &&
                    estimate["Bedrooms"] === userPropertyDetails.bedrooms &&
                    estimate["Bathrooms"] === userPropertyDetails.bathrooms
                );
            });
            let lowPrice = parseCurrencyString(propertyEstimateObj.Low);
            let highPrice = parseCurrencyString(propertyEstimateObj.High);
            let avgPrice = (lowPrice + highPrice) / 2;
            propertyEstimateObj.Avg = formatNumberToCurrency(avgPrice);

            setPropertyEstimate(propertyEstimateObj);

            setTimeout(() => {
                setShowMap(true);
            }, 1000);
        } else {
            console.log("No user property details found");
            navigate("/about-your-home");
        }
        // eslint-disable-next-line
    }, []);

    return (
        <Main>
            <MainRow>
                <EstimateDetails>
                    <EDTop>
                        <T1>{userDetails && userDetails?.address}</T1>
                        <Line />
                        <Row>
                            <Col>
                                <T2>Type</T2>
                                <T2>{userDetails && userDetails?.propertyType}</T2>
                            </Col>
                            <Col>
                                <T2>Bedrooms</T2>
                                <T2>{userDetails && userDetails?.bedrooms}</T2>
                            </Col>
                            <Col>
                                <T2>Bathrooms</T2>
                                <T2>{userDetails && userDetails?.bathrooms}</T2>
                            </Col>
                        </Row>
                    </EDTop>

                    <EDBtm>
                        <EstimateGrey>
                            <Label>Low Estimate</Label>
                            <Price>{propertyEstimate && propertyEstimate?.Low}</Price>
                        </EstimateGrey>
                        <EstimateOrange>
                            <LabelWhite>Average Price</LabelWhite>
                            <PriceWhite>{propertyEstimate && propertyEstimate?.Avg}</PriceWhite>
                        </EstimateOrange>
                        <EstimateGrey>
                            <Label>High Estimate</Label>
                            <Price>{propertyEstimate && propertyEstimate?.High}</Price>
                        </EstimateGrey>
                    </EDBtm>
                </EstimateDetails>

                {showMap ? (
                    <MapContainer>
                        <MapWithMarker
                            center={{
                                lat: userDetails.lat ? userDetails.lat : -3.9,
                                lng: userDetails.lng ? userDetails.lng : 1.14,
                            }}
                        />
                    </MapContainer>
                ) : (
                    <p>Loading...</p>
                )}
            </MainRow>

            <BtmRow>
                <Video>
                    <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/84zBeY3s2Lk/?autoplay=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                    ></iframe>
                </Video>

                <SignupDiv>
                    <H1>Expert guidance at your fingertips</H1>
                    {success ? (
                        <LabelWhite2>Great, we'll be in touch with you shortly {name}!</LabelWhite2>
                    ) : (
                        <>
                            <LabelWhite2>
                                Leave your contact information for a personalized property valuation
                                consultation.
                            </LabelWhite2>
                            <Input
                                placeholder="Your name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            <Input
                                placeholder="Your phone number"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                            />

                            {loading ? (
                                <Spinner />
                            ) : (
                                <BtnSecondary onClick={() => submit()}>Submit</BtnSecondary>
                            )}
                        </>
                    )}
                </SignupDiv>
            </BtmRow>
        </Main>
    );
};

export default Estimate;

const Main = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 30px;
    width: calc(100% - 60px);

    @media (max-width: 560px) {
        margin: 20px;
        width: calc(100% - 40px);
    }
`;

const MainRow = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    margin-bottom: 15px;

    @media (max-width: 560px) {
        flex-direction: column;
    }
`;

const EstimateDetails = styled.div`
    width: 60%;
    background-color: #f7f4f2;
    margin-right: 15px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;

    @media (max-width: 560px) {
        width: 100%;
    }
`;

const EDTop = styled.div`
    width: 100%;
    border-radius: 30px;
    background-color: #e2b15d;
    box-shadow: 0px 5px 30px 10px rgba(91, 51, 30, 0.1);
    padding: 15px 30px;
`;

const T1 = styled.p`
    color: white;
    text-align: center;
    font-size: 18px;
`;

const Line = styled.div`
    width: 100%;
    height: 2px;
    background-color: #f0cc8d;
    margin-bottom: 15px;
`;

const Row = styled.div`
    width: 100%;
    display: flex;
`;

const Col = styled.div`
    flex: 1;
    padding; 5px;
`;

const T2 = styled.p`
    color: white;
    font-size: 15px;
    margin: 5px 0;
`;

const EDBtm = styled.div`
    display: flex;
    padding: 15px;
    flex: 1;
    justify-content: center;

    @media (max-width: 560px) {
        flex-direction: column;
    }
`;

const EstimateGrey = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
`;

const Label = styled.p`
    color: #1c212b;
    margin-bottom: 0;
`;

const Price = styled.p`
    color: #1c212b;
    font-size: 30px;
    font-weight: 600;
    margin-top: 0;
`;

const EstimateOrange = styled(EstimateGrey)`
    background-color: #e2b15d;
    border-radius: 30px;
    flex: 1;
`;

const LabelWhite = styled(Label)`
    color: white;
`;

const PriceWhite = styled(Price)`
    color: white;
`;

const MapContainer = styled.div`
    width: 40%;
    border-radius: 30px;

    @media (max-width: 560px) {
        width: 100%;
    }
`;

const BtmRow = styled.div`
    margin-top: 15px;
    width: 100%;
    flex: 1;
    display: flex;

    @media (max-width: 560px) {
        flex-direction: column;
    }
`;

const Video = styled.div`
    // background-color: #f7f4f2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
    margin-right: 15px;
    border-radius: 30px;

    @media (max-width: 560px) {
        margin-right: 0px;
        width: 100%;
        margin-bottom: 20px;
    }
`;

const SignupDiv = styled.div`
    background-color: #7f5137;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 30px;
    width: 60%;
    margin-left: 15px;
    border-radius: 30px;

    @media (max-width: 560px) {
        width: 100%;
        margin-left: 0px;
    }
`;

const H1 = styled.h2`
    color: white;
    margin: 0;
    font-size: 28px;
    margin-bottom: 20px;
`;

const LabelWhite2 = styled(LabelWhite)`
    font-size: 15px;
    margin-bottom: 30px;
`;

const Input = styled(InputRaw)`
    margin-top: 10px;
    padding: 3px 10px;

    width: 70%;
    max-width: 280px;
    font-size: 16px;
    font-weight: 300;

    ::placeholder {
        font-size: 16px;
        font-weight: 300;
        opacity: 0.5;
    }
`;

const parseCurrencyString = (currencyString) => {
    // eslint-disable-next-line
    const cleanString = currencyString.replace(/[\$,]/g, "");
    return parseInt(cleanString);
};

const formatNumberToCurrency = (num) => {
    const str = num.toString();
    let formatted = "";
    let counter = 0;
    for (let i = str.length - 1; i >= 0; i--) {
        counter++;
        if (counter === 4) {
            formatted = "," + formatted;
            counter = 1;
        }
        formatted = str[i] + formatted;
    }
    formatted = "$" + formatted;
    return formatted;
};
