import styled from "styled-components";

const Header = () => {
    return (
        <Main>
            <Logo src={require("../assets/bvr-logo.png")} />

            <Privacy href="https://www.bvrhouse.ca/privacy-policy" target="_blank">
                Privacy Policy
            </Privacy>
        </Main>
    );
};

export default Header;

const Main = styled.div`
    width: 100%;
    padding: 20px 30px;
    display: flex;
    justify-content: space-between;
`;

const Logo = styled.img`
    width: 70px;
    object-fit: contain;
`;

const Privacy = styled.a`
    text-decoration: underline;
    font-size: 14px;
`;
