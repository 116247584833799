import styled from "styled-components";
import { Outlet } from "react-router-dom";

import Header from "../components/Header";

const MainWrapper = () => {
    return (
        <Main>
            <Header />
            <H1>Get a quick estimate of your property's value</H1>
            <P1>
                Use our calculator for a rough range of how much your property is worth, then sign
                up for a precise home value analysis from our expert beaver paws.
            </P1>
            <Outlet />
        </Main>
    );
};

export default MainWrapper;

const Main = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const H1 = styled.h1`
    font-size: 42px;
    line-height: 50px;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
    max-width: 80%;

    @media (max-width: 560px) {
        font-size: 28px;
        max-width: 90%;
        margin-bottom: 10px;
        line-height: 1.2;
    }
`;

const P1 = styled.p`
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    max-width: 60%;

    @media (max-width: 560px) {
        font-size: 14px;
        max-width: 90%;
    }
`;
