import { useEffect } from "react";
import styled from "styled-components";
import { RouterProvider } from "react-router-dom";

import router from "./pages";
import { sendAmplitudeEvent } from "./utils/amplitude";

function App() {
    useEffect(() => {
        sendAmplitudeEvent("App Loaded");
    });

    return (
        <Main>
            <RouterProvider router={router} />
        </Main>
    );
}

export default App;

const Main = styled.div`
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`;
